import React from "react"
import AnekdotesWhite from "../img/anekdotes-white.svg"
import Instagram from "../img/instagram.svg"
const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div className="columns">
          <div className="column">
            <img src={AnekdotesWhite} className="logo"></img>
          </div>
          <div className="column">
            <h3>Enquiry:</h3>
            <p>
              +91 98840 86694<br></br> inboxanekdotes@gmail.com
            </p>
            <br></br>
            <a href="https://www.instagram.com/anekdotes_studio/">
              <img src={Instagram} width="40px"></img>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
