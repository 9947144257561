import React from "react"
import { Helmet } from "react-helmet"
const Header = () => {
  return (
    <Helmet>
      <html lang="en" />
      <meta charset="UTF-8" />
      <meta property="og:locale" content="en_US" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index,follow" />
      <meta name="robots" content="all" />
      <link rel="canonical" href="https://anekdotes.in/" />
      <title>ANEKDOTES - Digital Content Studio</title>
      <link
        rel="icon"
        href="https://firebasestorage.googleapis.com/v0/b/anekdotes-14d79.appspot.com/o/anekdotesfavicon.png?alt=media&token=b2d7a9e9-5566-460b-b398-3501184adb73"
        type="image/png"
      />
      <meta property="og:title" content="ANEKDOTES - Digital Content Studio" />
      <meta
        name="description"
        content="ANEKDOTES, founded by Abbhinav Kastura, is a Digital Content Studio that develops Web Shows and Film scripts."
      ></meta>

      <meta
        name="og:description"
        content="ANEKDOTES, founded by Abbhinav Kastura, is a Digital Content Studio that develops Web Shows and Film scripts."
      ></meta>
    </Helmet>
  )
}
export default Header
